import React, { useEffect } from "react";
import { Separator } from "singulier";
import { PageCard, PageContainer } from "../layout";
import { useMatomo } from "@datapunt/matomo-tracker-react";

const NotAvailable = () => {
  const { trackPageView } = useMatomo();

  useEffect(() => {
    trackPageView();
  }, []);

  return (
    <PageContainer style={{ color: "#ffffff" }}>
      <PageCard
        style={{
          minHeight: "90vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src={require("../assets/logo-white-small.png").default} />
        <Separator size={20} />
        <h1>Radiotal is not available in your country.</h1>
        Check our website at a later date for updates.
      </PageCard>
    </PageContainer>
  );
};

export default NotAvailable;
