import React, { useEffect } from "react";
import {
  Button,
  centered,
  centeredCol,
  Column,
  PageCard,
  PageContainer,
  Row,
} from "../layout";
import {useMatomo} from "@datapunt/matomo-tracker-react";

const Broadcasters = () => {
  const { trackPageView } = useMatomo();

  useEffect(() => {
    trackPageView();
    window.scrollTo(0, 0);
  }, []);

  return (
    <PageContainer style={{ color: "#ffffff" }}>
      <PageCard style={{ flexDirection: "column", padding: "20px 80px" }}>
        <h1>Broadcasters</h1>
        <p>
          For any enquiries related to adding, updating or removing a station
          from Radiotal, please use our support ticketing system accessible
          below -
        </p>
        <div style={{ ...centered, marginTop: 20, marginBottom: 20 }}>
          <a
            href="https://www.radiotal.com/support/index.php?a=add&category=2"
            target="_blank"
          >
            <Button style={{ backgroundColor: "#f9ca24", color: "#000000" }}>
              go to support
            </Button>
          </a>
        </div>
        <p>
          Please note that Radiotal does not provide any stream itself but
          rather uses a third party open source database of radio stations.
        </p>
        <Row>
          <Column flex={1} style={{...centeredCol, alignItems: 'flex-start'}}>
            <h3>Promotions</h3>
            <p>
              If you would like to promote your radio station in our application
              by adding it in various first-page collections like Featured
              Stations, don't hesitate to get in touch with us using the same
              support button above!
            </p>
          </Column>
          <Column flex={0}>
            <img
              src={require("../assets/sampleFeaturedStations.png").default}
              style={{ width: "180px", height: "180px", borderRadius: 20 }}
            />
          </Column>
        </Row>
      </PageCard>
    </PageContainer>
  );
};

export default Broadcasters;
