import React, { useEffect } from "react";
import styled from "styled-components";
import CookieConsent from "react-cookie-consent";
import { Routes, Route, Link } from "react-router-dom";
import useGeoLocation from "react-ipgeolocation";
import ReactGA from "react-ga4";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import "./App.scss";
import {
  Column,
  Row,
  Button,
  centered,
  TextButton,
  HorizontalList,
} from "./layout";
import TermsOfUse from "./screens/TermsOfUse";
import Home from "./screens/Home";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import CookiePolicy from "./screens/CookiePolicy";
import NotAvailable from "./screens/NotAvailable";
import Broadcasters from "./screens/Broadcasters";

const blacklist = ["GB", "UK", "BR", "JP", "JA", "KR", "NK", "PK"];

const appLink =
  "https://play.google.com/store/apps/details?id=com.colorcode.radiotal";

const Header = styled.div`
  width: 100%;
  padding: 20px 40px;
  background: #00000000;
  position: fixed;
  z-index: 111;
  backdrop-filter: blur(5px);

  @media (max-width: 1000px) {
    padding: 10px;
  }
`;

const Logo = styled.img`
  height: 18px;
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 40px;
  color: #ffffff;

  @media (max-width: 1000px) {
    text-align: center;
  }
`;

const FooterLink = styled.div`
  color: #ffffff;
  padding: 10px 10px 0 10px;
  text-decoration: none !important;

  @media (max-width: 1000px) {
    text-align: center;
  }

  a {
    color: #ffffff;
    text-decoration: none !important;

    &:hover {
      color: #777777;
      cursor: pointer;
    }
  }
`;

const HeaderRow = styled(Row)`
  flex-direction: row !important;
`;

const Soon = styled.div`
  display: inline-block;
  font-size: 11px;
  color: #130f40;
  background-color: #f9ca24;
  border-radius: 8px;
  padding: 2px 4px;
  margin-left: 4px;
`;

function App() {
  const location = useGeoLocation();

  const notAvailable = blacklist.indexOf(location.country) > -1;
  const { trackPageView } = useMatomo();

  useEffect(() => {
    ReactGA.initialize("G-70NTZ0DJ3X");
    ReactGA.pageview(window.location.pathname + window.location.search);

    trackPageView();
  }, [trackPageView]);

  return (
    <div className="App">
      <Header>
        <HeaderRow>
          <Column flex={0} style={centered}>
            <div
              style={{
                padding: "8px 16px",
                borderRadius: 16,
                backgroundColor: "#00000011",
                backdropFilter: "blur(5px)",
              }}
            >
              <Link to={"/"} style={centered}>
                <Logo src={require("./assets/logo-white.png").default} />
              </Link>
            </div>
          </Column>
          <Column flex={1} />
          <Column flex={0}>
            <HorizontalList>
              {!notAvailable && (
                <>
                  <TextButton className="hide-small">
                    web player
                    <Soon>soon</Soon>
                  </TextButton>
                  <a href={appLink} target="_blank">
                    <Button>get the app</Button>
                  </a>
                </>
              )}
            </HorizontalList>
          </Column>
        </HeaderRow>
      </Header>
      <Routes>
        <Route path="/terms-of-use" element={<TermsOfUse />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/cookie-policy" element={<CookiePolicy />} />
        <Route path="/broadcasters" element={<Broadcasters />} />
        <Route path="/" element={notAvailable ? <NotAvailable /> : <Home />} />
      </Routes>
      <Row>
        <Column flex={1} style={{ backgroundColor: "#30336b" }}>
          <FooterContent>
            <Row>
              <Column flex={1}>
                <h4>About</h4>
                <Link to={"/terms-of-use"}>
                  <FooterLink>Terms of Use</FooterLink>
                </Link>
                <Link to={"/privacy-policy"}>
                  <FooterLink>Privacy Policy</FooterLink>
                </Link>
                <Link to={"/cookie-policy"}>
                  <FooterLink>Cookie Policy</FooterLink>
                </Link>
                <FooterLink>
                  <a href="https://www.aboutcookies.com/" target="_blank">
                    About Cookies
                  </a>
                </FooterLink>
              </Column>
              <Column flex={1}>
                <h4>Social</h4>
                <FooterLink>
                  <a href="https://facebook.com/radiotal" target="_blank">
                    Facebook
                  </a>
                </FooterLink>
                <FooterLink>
                  <a
                    href="https://www.instagram.com/radiotalapp/"
                    target="_blank"
                  >
                    Instagram
                  </a>
                </FooterLink>
              </Column>
              <Column flex={1}>
                <h4>Links</h4>
                {!notAvailable && (
                  <>
                    <FooterLink>
                      <a href={appLink} target="_blank">
                        Android app
                      </a>
                    </FooterLink>
                    <FooterLink>
                      Web player <Soon>soon</Soon>
                    </FooterLink>
                    <Link to="/broadcasters">
                      <FooterLink>Broadcasters</FooterLink>
                    </Link>
                    <FooterLink>
                      <a
                        href={"https://radiotal.betteruptime.com"}
                        target="_blank"
                      >
                        Status page
                      </a>
                    </FooterLink>
                  </>
                )}
                <FooterLink>
                  <a href="https://radiotal.com/support/">Support</a>
                </FooterLink>
              </Column>
              <Column flex={0}>
                <div style={{ marginTop: "1.33em", marginBottom: "1.33em" }}>
                  <img src={require("./assets/logo-white-small.png").default} />
                </div>
                <div>&copy; Colorcode 2023</div>
              </Column>
            </Row>
          </FooterContent>
        </Column>
      </Row>
      <CookieConsent
        location="bottom"
        buttonText="Sounds good!"
        cookieName="radiotalWebsiteCookie"
        style={{
          background: "#2B373BCC",
          borderRadius: "16px",
          margin: "20px",
          boxSizing: "border-box",
          width: "calc(100% - 40px)",
        }}
        buttonStyle={{
          border: 0,
          borderRadius: "16px",
          backgroundColor: "#130f40",
          color: "#ffffff",
          padding: "8px 16px",
          fontSize: "14px",
        }}
        buttonClasses={"consent-button-hover"}
        expires={150}
      >
        This website uses cookies to enhance the user experience.{" "}
        <a
          href="https://www.aboutcookies.com/"
          target="_blank"
          style={{ color: "#ffffff", textDecoration: "underline" }}
        >
          Click here
        </a>{" "}
        to find out more about cookies or{" "}
        <Link
          to={"cookie-policy"}
          style={{ color: "#ffffff", textDecoration: "underline" }}
        >
          here
        </Link>{" "}
        to learn more about our cookie policy.
      </CookieConsent>
    </div>
  );
}

export default App;
