import React, { useEffect } from "react";
import { PageCard, PageContainer } from "../layout";
import {useMatomo} from "@datapunt/matomo-tracker-react";

const CookiePolicy = () => {
  const { trackPageView } = useMatomo();

  useEffect(() => {
    trackPageView();
    window.scrollTo(0, 0);
  }, []);

  return (
    <PageContainer style={{ color: "#ffffff" }}>
      <PageCard>
        <p style={{ marginTop: 0 }}>Updated at 2021-12-19</p>

        <h1>Cookie Policy</h1>

        <h1>Definitions and key terms</h1>
        <p>
          To help explain things as clearly as possible in this Cookie Policy,
          every time any of these terms are referenced, are strictly defined as:
          <ul>
            <li>
              Cookie: small amount of data generated by a website and saved by
              your web browser. It is used to identify your browser, provide
              analytics, remember information about you such as your language
              preference or login information.
            </li>
            <li>
              Company: when this policy mentions “Company,” “we,” “us,” or
              “our,” it refers to Radiotal, that is responsible for your
              information under this Cookie Policy.
            </li>
            <li>
              Device: any internet connected device such as a phone, tablet,
              computer or any other device that can be used to visit Radiotal
              and use the services.
            </li>
            <li>
              Personal Data: any information that directly, indirectly, or in
              connection with other information — including a personal
              identification number — allows for the identification or
              identifiability of a natural person.
            </li>
            <li>
              Service: refers to the service provided by Radiotal as described
              in the relative terms (if available) and on this platform.{" "}
            </li>
            <li>
              Third-party service: refers to advertisers, contest sponsors,
              promotional and marketing partners, and others who provide our
              content or whose products or services we think may interest you.
            </li>
            <li>
              Website: site, which can be accessed via this URL:
              www.radiotal.com
            </li>
          </ul>
        </p>

        <h1>Introduction</h1>
        <p>
          This Cookie Policy explains how Radiotal and its affiliates
          (collectively "Radiotal", "we", "us", and "ours"), use cookies and
          similar technologies to recognize you when you visit our website/app,
          including without limitation www.radiotal.com and any related URLs,
          mobile or localized versions and related domains / sub-domains
          ("Websites"). It explains what these technologies are and why we use
          them, as well as the choices for how to control them.
        </p>

        <h1>What is a cookie?</h1>
        <p>
          A cookie is a small text file that is stored on your computer or other
          internet connected device in order to identify your browser, provide
          analytics, remember information about you such as your language
          preference or login information. They're completely safe and can't be
          used to run programs or deliver viruses to your device.
        </p>

        <h1>Why do we use cookies?</h1>
        <p>
          We use first party and/or third party cookies on our website/app for
          various purposes such as:
        </p>
        <ul>
          <li>
            To facilitate the operation and functionality of our website/app;
          </li>
          <li>
            To improve your experience of our website/app and make navigating
            around them quicker and easier;
          </li>
          <li>
            To allow us to make a bespoke user experience for you and for us to
            understand what is useful or of interest to you;
          </li>
          <li>
            To analyze how our website/app is used and how best we can customize
            it;
          </li>
          <li>
            To identify future prospects and personalize marketing and sales
            interactions with it;
          </li>
          <li>
            To facilitate the tailoring of online advertising to your interests.
          </li>
          <li>
            You: a person or entity that is registered with Radiotal to use the
            Services.
          </li>
        </ul>

        <h1>What type of cookies does Radiotal use?</h1>
        <p>
          Cookies can either be session cookies or persistent cookies. A session
          cookie expires automatically when you close your browser. A persistent
          cookie will remain until it expires or you delete your cookies.
          Expiration dates are set in the cookies themselves; some may expire
          after a few minutes while others may expire after multiple years.
          Cookies placed by the website you’re visiting are called “first party
          cookies”.
        </p>
        <p>
          Strictly Necessary cookies are necessary for our website/app to
          function and cannot be switched off in our systems. They are essential
          in order to enable you to navigate around the website/app and use its
          features. If you remove or disable these cookies, we cannot guarantee
          that you will be able to use our website/app.
        </p>
        <p>We use the following types of cookies in our website/app:</p>
        <h1>Essential Cookies</h1>
        <p>
          We use essential cookies to make our website/app work. These cookies
          are strictly necessary to enable core functionality such as security,
          network management, your cookie preferences and accessibility. Without
          them you wouldn't be able to use basic services. You may disable these
          by changing your browser settings, but this may affect how the
          Websites function.
        </p>
        <h1>Performance and Functionality Cookies</h1>
        <p>
          These cookies are used to enhance the performance and functionality of
          our website/app but are non-essential to their use. However, without
          these cookies, certain functionality like videos may become
          unavailable or you would be required to enter your login details every
          time you visit the website/app as we would not be able to remember
          that you had logged in previously.
        </p>
        <h1>Marketing Cookies</h1>
        <p>
          These account-based marketing cookies enable us to identify future
          prospects and personalize sales and marketing interactions with them.
        </p>
        <h1>Analytics and Customization Cookies</h1>
        <p>
          These cookies collect information that is used to help us understand
          how our website/app is being used or how effective our marketing
          campaigns are, or to help us customize our website/app for you.
        </p>
        <p>
          We use cookies served by Google Analytics to collect limited data
          directly from end-user browsers to enable us to better understand your
          use of our website/app. Further information on how Google collects and
          uses this data can be found
          at: https://www.google.com/policies/privacy/partners/. You can opt-out
          of all Google supported analytics on our Websites by
          visiting: https://tools.google.com/dlpage/gaoptout.
        </p>
        <h1>Advertising Cookies</h1>
        <p>
          These cookies collect information over time about your online activity
          on the website/app and other online services to make online
          advertisements more relevant and effective to you. This is known as
          interest-based advertising. They also perform functions like
          preventing the same ad from continuously reappearing and ensuring that
          ads are properly displayed for advertisers. Without cookies, it’s
          really hard for an advertiser to reach its audience, or to know how
          many ads were shown and how many clicks they received.
        </p>
        <h1>Social Media Cookies</h1>
        <p>
          These cookies are used when you share information using a social media
          sharing button or “like” button on our Site or you link your account
          or engage with our content on or through a social networking website
          such as Facebook, Twitter or Google+. The social network will record
          that you have done this. These cookies may also include certain code
          that has been placed on the platform to help track conversions from
          ads, optimize ads based on collected data, build targeted audiences
          for future ads, and remarket to qualified users who have already taken
          certain action on the platform.
        </p>
        <h1>Third Party Cookies</h1>
        <p>
          Some cookies that have been set on our website/app are not set on a
          first party basis by Radiotal. The Websites can be embedded with
          content from third parties to serve advertising. These third party
          service providers may set their own cookies on your web browser. Third
          party service providers control many of the performance and
          functionality, advertising, marketing and analytics cookies described
          above. We do not control the use of these third party cookies as
          cookies can only be accessed by the third party that originally set
          them.
        </p>
        <h1>How you can manage cookies?</h1>
        <p>
          Most browsers allow you to control cookies through their 'settings'
          preferences. However, if you limit the ability of websites to set
          cookies, you may worsen your overall user experience, since it will no
          longer be personalized to you. It may also stop you from saving
          customized settings like login information. Browser manufacturers
          provide help pages relating to cookie management in their products.
        </p>
        <p>
          Browser manufacturers provide help pages relating to cookie management
          in their products. Please see below for more information.
        </p>
        <ul>
          <li>Google Chrome</li>
          <li>Internet Explorer</li>
          <li>Mozilla Firefox</li>
          <li>Safari (Desktop)</li>
          <li>Safari (Mobile)</li>
          <li>Android Browser</li>
          <li>Opera</li>
          <li>Opera Mobile</li>
        </ul>

        <h1>Blocking and disabling cookies and similar technologies</h1>
        <p>
          Wherever you're located you may also set your browser to block cookies
          and similar technologies, but this action may block our essential
          cookies and prevent our website from functioning properly, and you may
          not be able to fully utilize all of its features and services. You
          should also be aware that you may also lose some saved information
          (e.g. saved login details, site preferences) if you block cookies on
          your browser. Different browsers make different controls available to
          you. Disabling a cookie or category of cookie does not delete the
          cookie from your browser, you will need to do this yourself from
          within your browser, you should visit your browser's help menu for
          more information.
        </p>

        <h1>Changes To Our Cookie Policy</h1>
        <p>
          We may change our Service and policies, and we may need to make
          changes to this Cookie Policy so that they accurately reflect our
          Service and policies. Unless otherwise required by law, we will notify
          you (for example, through our Service) before we make changes to this
          Cookie Policy and give you an opportunity to review them before they
          go into effect. Then, if you continue to use the Service, you will be
          bound by the updated Cookie Policy. If you do not want to agree to
          this or any updated Cookie Policy, you can delete your account.
        </p>

        <h1>Your Consent</h1>
        <p>
          By using our website/app, registering an account, or making a
          purchase, you hereby consent to our Cookie Policy and agree to its
          terms.
        </p>

        <h1>Contact Us</h1>
        <p>Don't hesitate to contact us if you have any questions.</p>
        <ul>
          <li>
            Via Email:{" "}
            <a href="mailto:support@radiotal.com">support@radiotal.com</a>
          </li>
          <li>
            Via this Link:{" "}
            <a href="https://www.radiotal.com/support" target="_blank">
              https://www.radiotal.com/support
            </a>
          </li>
        </ul>
      </PageCard>
    </PageContainer>
  );
};

export default CookiePolicy;
