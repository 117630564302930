import styled from "styled-components";

export const Row = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const Column = styled.div`
  ${({ flex }) => (flex ? "flex: " + flex + ";" : "")}
`;

export const centered = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
};

export const centeredCol = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};

export const Button = styled.button`
  border: 0;
  border-radius: 16px;
  background-color: #130f40;
  color: #ffffff;
  padding: 8px 16px;
  font-size: 14px;

  &:hover {
    filter: brightness(1.2);
    cursor: pointer;
  }

  &:active {
    filter: brightness(1.5);
    cursor: pointer;
  }
`;

export const TextButton = styled.button`
  border: 0;
  border-radius: 16px;
  background-color: #ffffff00;
  color: #ffffff;
  padding: 8px 16px;
  font-size: 14px;

  &:hover {
    background-color: #ffffff11;
    cursor: pointer;
  }

  &:active {
    background-color: #ffffff22;
    cursor: pointer;
  }

  @media (max-width: 1000px) {
    display: none;
  }
`;

export const HorizontalList = styled.div`
  & > * {
    margin-right: 8px;
  }

  & > *:last-child {
    margin-right: 0;
  }
`;

export const Separator = styled.div`
  display: block;
  width: 100%;
  height: ${({ size }) => (size ? size + "px" : "10px")};
`;

export const Container = styled.div`
  width: 100%;
`;

export const PageContainer = styled.div`
  padding-top: 80px;
  margin: 0 auto;
  max-width: 1400px;
  padding-bottom: 20px;
  min-height: calc(100vh - 200px);
`;

export const PageCard = styled.div`
  border-radius: 16px;
  background: #1B1464;
  padding: 20px;
  
  a {
    color: #ffffff;
  }
`;
