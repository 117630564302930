import React, { useEffect } from "react";
import styled, { createGlobalStyle } from "styled-components";
import {
  Button,
  centered,
  HorizontalList,
  Row,
  Separator,
  TextButton,
} from "../layout";
import Wave from "../components/wave";
import { useMatomo } from "@datapunt/matomo-tracker-react";

const appLink = "https://play.google.com/store/apps/details?id=com.colorcode.radiotal";

const GlobalStyles = createGlobalStyle`
  body {
  }
`;

const Column = styled.div`
  ${({ flex }) => (flex ? "flex: " + flex + ";" : "")}
  overflow: hidden;

  @media (max-width: 1000px) {
    margin: 0;
    border-radius: 0;
  }
`;

const Content = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  min-height: calc(100vh - 20px);
`;

const FullWidthContent = styled.div`
  width: 100%;
  margin: 0 auto;
  min-height: calc(100vh - 20px);
  position: relative;
`;

const Jumbo = styled.div`
  font-size: 4em;
  color: #ffffff;
  font-weight: bold;
  line-height: 1em;
  margin-bottom: 1rem;
  text-align: center;
  padding: 0 10px;
  text-shadow: 0 0 8px #000000;

  @media (max-width: 1000px) {
    font-size: 2.5em;
  }
`;

const JumboSmall = styled.div`
  font-size: 1.5em;
  color: #ffffff;
  margin-bottom: 1rem;
  text-align: center;
`;

const JumboSmaller = styled.div`
  font-size: 1.1em;
  //font-weight: bold;
  color: #ffffff;
  margin-bottom: 1rem;
  text-align: center;
  padding: 0 40px;
  background-color: #00000077;
  border-radius: 16px;
`;

const JumboSmallest = styled.div`
  font-size: 0.9em;
  //font-weight: bold;
  color: #ffffff;
  margin-bottom: 1rem;
  text-align: center;
  padding: 0 40px;
  background-color: #00000077;
  border-radius: 16px;
`;

const JumboButton = styled(Button)`
  background-color: #303f6b;
  font-size: 1.2em;
  border: solid 2px #a9a4e9;
`;

const Mock = styled.img`
  max-height: 80vh;
`;

const WaveContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  top: 50%;
  transform: translateY(-100px);
`;

const BgImgCover = styled.div`
  background-image: url(${({ img }) => img});
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-size: cover;
  background-position: center;
  z-index: -2;
`;

const BgImg = styled.div`
  background-image: url(${require("../assets/art/_0eb65f67-7da3-4d72-a1b8-1c9c4e709f85.jpeg")
    .default});
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-size: cover;
  background-position: center;
  z-index: -2;
  transform: scale(1.1);
`;

const BgImg2 = styled(BgImg)`
  background-image: url(${require("../assets/art/_05952d9a-1ff6-43d6-b529-309ba14d0746.jpeg")
    .default});
`;

const BgImg3 = styled(BgImg)`
  background-image: url(${require("../assets/art/_be08815b-91f8-49cf-bfcc-629256eee551.jpeg")
    .default});
`;

const BgImg4 = styled(BgImg)`
  background-image: url(${require("../assets/art/_be08815b-91f8-49cf-bfcc-629256eee551.jpeg")
    .default});
`;

const BgImgOverlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: #130f4077;
  z-index: -1;
`;

const BgImgCredit = styled.div`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  color: #ffffff77;

  a {
    color: #ffffff77;
  }
`;

const Soon = styled.div`
  display: inline-block;
  font-size: 11px;
  color: #130f40;
  background-color: #f9ca24;
  border-radius: 8px;
  padding: 2px 4px;
  margin-left: 4px;
`;

const Home = () => {
  const { trackPageView } = useMatomo();

  useEffect(() => {
    trackPageView();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <GlobalStyles />
      <Row>
        <Column flex={1} style={{ position: "relative" }}>
          <BgImg2 />
          <BgImgOverlay style={{ backgroundColor: "#130f4077" }} />
          <Content style={{ ...centered, flexDirection: "column" }}>
            <Jumbo style={centered}>surround yourself with music</Jumbo>
            <a href={appLink} target="_blank">
              <JumboButton>get the mobile app</JumboButton>
            </a>
            <Separator />
            <JumboSmallest>
              available on Android devices, coming soon on iOS
            </JumboSmallest>
          </Content>
        </Column>
        <Column
          flex={1}
          style={{ backgroundColor: "#000000", position: "relative" }}
        >
          <WaveContainer className="hide-small">
            <Wave />
          </WaveContainer>

          <Content style={{ ...centered, flexDirection: "column" }}>
            <Mock
              src={require("../assets/mock1.png").default}
              style={{ zIndex: 1 }}
            />
            <HorizontalList>
              <a href={appLink} target="_blank">
                <Button
                  style={{ backgroundColor: "#f9ca24", color: "#000000" }}
                >
                  get the android app
                </Button>
              </a>
            </HorizontalList>
          </Content>
        </Column>
      </Row>

      <Row>
        <Column flex={1}>
          <FullWidthContent
            style={{
              ...centered,
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <BgImgCover
              img={
                require("../assets/art/_b0974d9a-95df-40f6-9184-ccaa50fb6290.jpeg")
                  .default
              }
            />
            <BgImgOverlay style={{ backgroundColor: "#130f4033" }} />
            <Jumbo>something for everyone</Jumbo>
            <Separator />
            <JumboSmallest>
              tens of thousands of stations available, zero chances of getting
              bored
            </JumboSmallest>
            <Separator size={100} />
          </FullWidthContent>
        </Column>
      </Row>
      <Row>
        <Column flex={1} style={{ backgroundColor: "#576574" }}>
          <Content
            style={{
              ...centered,
              flexDirection: "column",
              position: "relative",
            }}
          >
            <Jumbo style={{ marginTop: "40px" }}>create collections</Jumbo>
            <Mock src={require("../assets/mock2.png").default} />
            <JumboSmaller>
              and skip through stations like you'd skip through songs
            </JumboSmaller>
          </Content>
        </Column>
        <Column flex={1} style={{ position: "relative" }}>
          <BgImg />
          <BgImgOverlay style={{ backgroundColor: "#130f40aa" }} />

          <Content style={{ ...centered, flexDirection: "column" }}>
            <Jumbo>start listening</Jumbo>
            <a href={appLink} target="_blank">
              <JumboButton>get the mobile app</JumboButton>
            </a>
            <Separator size={10} />
            <TextButton>
              or go to the web player <Soon>soon</Soon>
            </TextButton>
            <Separator size={40} />
            <JumboSmaller>
              the front page of stations is changing every week - you can also
              save to favorites, discover stations from all around the 🌎, and
              you can listen without creating an account.
            </JumboSmaller>
          </Content>
        </Column>
      </Row>
    </>
  );
};

export default Home;
